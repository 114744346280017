import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Loading from "../components/Loading";
import moment from "moment";
import { Select } from "../components/styledComponent/formInputs";

const Attendance = () => {
  const [attendanceAdmin, setAttendanceAdmin] = useState([
    {
      name: "Adam Abdullahi Araga",
      present: "20",
      presentPer: "97",
      absent: "5",
      Late: "10",
      overtime: "10:30",
    },
    {
      name: "Adam Abdullahi Araga",
      present: "20",
      presentPer: "97",
      absent: "5",
      Late: "10",
      overtime: "10:30",
    },
    {
      name: "Adam Abdullahi Araga",
      present: "20",
      presentPer: "97",
      absent: "5",
      Late: "10",
      overtime: "10:30",
    },
    {
      name: "Adam Abdullahi Araga",
      present: "20",
      presentPer: "97",
      absent: "5",
      Late: "10",
      overtime: "10:30",
    },
  ]);
  const [attendance, setAttendance] = useState([
    {
      data: "2024-09-21T07:26:19.000Z",
      timeIn: "2024-09-21T22:07:00.000Z",
      timeOut: "2024-09-21T22:18:19.000Z",
      status: "Present",
      regularHours: "7:15",
      overTime: "0:19",
      totalHours: "7:34",
      percentage: "70",
    },
    {
      data: "2024-09-21T07:26:19.000Z",
      timeIn: "",
      timeOut: "",
      status: "Absent",
      regularHours: "",
      overTime: "",
      percentage: "0",
      totalHours: "",
    },
    {
      data: "2024-09-21T07:26:19.000Z",
      timeIn: "2024-09-21T22:07:00.000Z",
      timeOut: "2024-09-21T22:18:19.000Z",
      status: "Present",
      regularHours: "7:15",
      overTime: "0:19",
      totalHours: "7:34",
      percentage: "70",
    },
    {
      data: "2024-09-21T07:26:19.000Z",
      timeIn: "2024-09-21T22:07:00.000Z",
      timeOut: "2024-09-21T22:18:19.000Z",
      status: "Present",
      regularHours: "7:15",
      overTime: "0:19",
      totalHours: "7:34",
      percentage: "70",
    },
  ]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degrees) => (degrees * Math.PI) / 180;

    const R = 6371e3; // Earth's radius in meters
    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians(lat2 - lat1);
    const Δλ = toRadians(lon2 - lon1);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in meters
  };

  const geofenceCenter = {
    latitude: 9.113627577926488,
    longitude: 7.421715258876135,
  }; // Example geofence center
  const geofenceRadius = 60; // Radius in meters

  navigator.geolocation.getCurrentPosition((position) => {
    const { latitude, longitude } = position.coords;

    // Calculate distance to geofence center
    const distance = calculateDistance(
      latitude,
      longitude,
      geofenceCenter.latitude,
      geofenceCenter.longitude
    );

    if (distance > geofenceRadius) {
      // setStatus("You're outside the geofenced location!");
      // return;
    }

    console.log("distance", distance);
  });

  return (
    <div className="attendance">
      <div className="blog__main" style={{ marginBottom: 50 }}>
        <h2>Attendance</h2>
        <div style={{ marginTop: 10 }} className="attendance__main__filter">
          <Select name="" id="">
            <option value="january"> January</option>
            <option value="febuary"> febuary</option>
            <option value="year"> March</option>
          </Select>
        </div>
        <div className="dashboard__users__table" style={{ padding: 0 }}>
          <div
            className="dashboard__users__table__body"
            style={{ minWidth: 1000 }}
          >
            <div className="dashboard__users__table__row head">
              <div
                style={{ width: "200px" }}
                className="dashboard__users__table__row__head"
              >
                Name
              </div>
              <div
                style={{ width: "100px" }}
                className="dashboard__users__table__row__head"
              >
                Present Days
              </div>

              <div
                style={{ width: "100px" }}
                className="dashboard__users__table__row__head"
              >
                Present Days Percentage (%)
              </div>
              <div
                style={{ width: "100px" }}
                className="dashboard__users__table__row__head"
              >
                Absent Days
              </div>
              <div
                style={{ width: "100px" }}
                className="dashboard__users__table__row__head"
              >
                Late Days
              </div>
              <div
                style={{ width: "100px" }}
                className="dashboard__users__table__row__head"
              >
                Overtime (hours)
              </div>
            </div>

            {loading ? (
              <Loading />
            ) : (
              attendanceAdmin?.map((attend, i) => {
                return (
                  <div key={i} className="dashboard__users__table__row">
                    <div
                      style={{ width: "200px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {attend.name}
                    </div>
                    <div
                      style={{ width: "100px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {attend.present}
                    </div>
                    <div
                      style={{ width: "100px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {attend.presentPer}
                    </div>
                    <div
                      style={{ width: "100px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {attend.absent}
                    </div>
                    <div
                      style={{ width: "100px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {attend.Late}
                    </div>
                    <div
                      style={{ width: "100px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {attend.overtime}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>

        {pageCount > 1 && (
          <div style={{ marginTop: 40 }}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              breakLabel="..."
              //   renderOnZeroPageCount={null}
              onPageChange={handlePageClick}
              // pageRangeDisplayed={itemsPerPage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        )}
      </div>

      <div className="blog__main">
        <h2>Attendance</h2>

        <div className="attendance__main">
          <div className="attendance__main__clock">
            <div className="attendance__main__clock__item">
              <i className="feather ft-log-in"></i>
              <h4>Clock in</h4>
            </div>
            <div className="attendance__main__clock__item out">
              <i className="feather ft-log-out"></i>
              <h4>Clock out</h4>
            </div>
          </div>

          <div className="attendance__main__filter">
            <Select name="" id="">
              <option value="january"> January</option>
              <option value="febuary"> febuary</option>
              <option value="year"> March</option>
            </Select>
          </div>
          <div className="attendance__main__stat">
            <div className="attendance__main__stat__item">
              <h4>Present Days</h4>
              <p>25</p>
            </div>
            <div className="attendance__main__stat__item">
              <h4>Present Days %</h4>
              <p>100%</p>
            </div>
            <div className="attendance__main__stat__item">
              <h4>Absent Days </h4>
              <p>5</p>
            </div>
            <div className="attendance__main__stat__item">
              <h4>Late Days</h4>
              <p>10</p>
            </div>
            <div className="attendance__main__stat__item">
              <h4>Overtime (hours)</h4>
              <p>10:00</p>
            </div>
          </div>

          <div className="dashboard__users__table" style={{ padding: 0 }}>
            <div
              className="dashboard__users__table__body"
              style={{ minWidth: 1000 }}
            >
              <div className="dashboard__users__table__row head">
                <div
                  style={{ width: "100px" }}
                  className="dashboard__users__table__row__head"
                >
                  Data
                </div>
                <div
                  style={{ width: "100px" }}
                  className="dashboard__users__table__row__head"
                >
                  Clock In
                </div>

                <div
                  style={{ width: "100px" }}
                  className="dashboard__users__table__row__head"
                >
                  Clock Out
                </div>
                <div
                  style={{ width: "100px" }}
                  className="dashboard__users__table__row__head"
                >
                  Status
                </div>
                <div
                  style={{ width: "100px" }}
                  className="dashboard__users__table__row__head"
                >
                  Regular Hours
                </div>
                <div
                  style={{ width: "100px" }}
                  className="dashboard__users__table__row__head"
                >
                  Present percentage (%)
                </div>
                <div
                  style={{ width: "100px" }}
                  className="dashboard__users__table__row__head"
                >
                  Overtime (hours)
                </div>
                <div
                  style={{ width: "100px" }}
                  className="dashboard__users__table__row__head"
                >
                  Total Hours
                </div>
              </div>

              {loading ? (
                <Loading />
              ) : (
                attendance?.map((attend, i) => {
                  return (
                    <div key={i} className="dashboard__users__table__row">
                      <div
                        style={{ width: "100px" }}
                        className="dashboard__users__table__row__data"
                      >
                        {attend.data &&
                          moment.utc(attend.data).format("Do MMM YYYY")}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="dashboard__users__table__row__data"
                      >
                        {attend.timeIn &&
                          moment.utc(attend.timeIn).format("h:mm a")}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="dashboard__users__table__row__data"
                      >
                        {attend.timeOut &&
                          moment.utc(attend.timeOut).format("h:mm a")}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="dashboard__users__table__row__data"
                      >
                        <div
                          className={
                            attend.status === "Present"
                              ? "attendance__tableStatus"
                              : "attendance__tableStatus abs"
                          }
                        >
                          {attend.status}
                        </div>
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="dashboard__users__table__row__data"
                      >
                        {attend.regularHours}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="dashboard__users__table__row__data"
                      >
                        {attend.percentage}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="dashboard__users__table__row__data"
                      >
                        {attend.overTime}
                      </div>
                      <div
                        style={{ width: "100px" }}
                        className="dashboard__users__table__row__data"
                      >
                        {attend.totalHours}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          {pageCount > 1 && (
            <div style={{ marginTop: 40 }}>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                breakLabel="..."
                //   renderOnZeroPageCount={null}
                onPageChange={handlePageClick}
                // pageRangeDisplayed={itemsPerPage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Attendance;
