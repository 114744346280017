import { useEffect, useState } from "react";
import logo from "../assets/images/svg/logo.svg";
import Button from "../common/Button";
import {
  ErrorMessage,
  Input,
  Label,
  Select,
} from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { addTraining } from "../api/usersApi";

const TrainingApp = () => {
  const [program, setprogram] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [price, setprice] = useState("");

  const [step, setStep] = useState(1);
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";
    let programError = "";
    let phoneError = "";

    if (!firstName) {
      firstNameError = "first name is required";
    }
    if (!lastName) {
      lastNameError = "last name is required";
    }
    if (!email) {
      emailError = "email is required";
    }
    if (!email.includes("@")) {
      emailError = "email is invalid";
    }
    if (!program) {
      programError = "program is required";
    }
    if (!phone) {
      phoneError = "mobile is required";
    }

    if (
      emailError ||
      lastNameError ||
      firstNameError ||
      programError ||
      phoneError ||
      emailError
    ) {
      setInputError((curr) => {
        return {
          ...curr,
          email: emailError,
          lastName: lastNameError,
          firstName: firstNameError,
          phone: phoneError,
          program: programError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let data = { email, program, firstName, phone, lastName };

        await addTraining(data);

        setLoading(false);
        // toast.success("Signup successfully");
        setStep(2);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  const programs = [
    { name: "Web Development", price: "₦450,000" },
    { name: "Mobile development", price: "₦800,000" },
    { name: "3D Animation", price: "₦300,000" },
    { name: "Cinematography", price: "₦400,000" },
    { name: "Photography", price: "₦500,000" },
    { name: "Digital Marketing", price: "₦200,000" },
    { name: "Graphic Designing", price: "₦200,000" },
    { name: "Drone Piloting", price: "₦800,000" },
    { name: "Video Editing", price: "₦900,000" },
  ];

  useEffect(() => {
    if (program) {
      const currProgram = programs.filter((item) => item.name === program);

      setprice(currProgram[0]?.price);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  return (
    <div className="careerApp">
      <div className="careerApp__header">
        <img src={logo} alt="Logo" />

        <h1>Enroll for Training</h1>
        <p>Unlock Your Potential with Our Expert Training Programs.</p>
      </div>

      {step === 1 && (
        <div className="careerApp__main">
          <p style={{ fontSize: 16 }}>
            Please complete the form below to apply for training with us.
          </p>
          <br />
          <h3 style={{ marginTop: 0 }}>Basic Info</h3>
          <div className="careerApp__main__input">
            <Label htmlFor="firstName">
              First Name <span>*</span>
            </Label>
            <Input
              id="firstName"
              value={firstName}
              onChange={(e) => setfirstName(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.firstName}</ErrorMessage>
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="lastName">
              Last Name <span>*</span>
            </Label>
            <Input
              id="lastName"
              value={lastName}
              onChange={(e) => setlastName(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.lastName}</ErrorMessage>
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="email">
              Email <span>*</span>
            </Label>
            <Input
              id="email"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.email}</ErrorMessage>
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="mobile">
              Mobile <span>*</span>
            </Label>
            <Input
              id="mobile"
              value={phone}
              onChange={(e) => setphone(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.phone}</ErrorMessage>
          </div>
          <h3>Training Program </h3>

          <div>
            <div className="careerApp__main__input">
              <Label htmlFor="program">
                Program <span>*</span>
              </Label>
              <Select
                id="program"
                value={program}
                onChange={(e) => {
                  setprogram(e.target.value);
                }}
              >
                <option value="">-- select Program --</option>
                {programs.map((item) => {
                  return (
                    <option key={item.name} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
              <ErrorMessage>{inputError?.program}</ErrorMessage>
            </div>

            {price && (
              <div>
                <h3>Program Training Price</h3>{" "}
                <p className="suc" style={{ fontSize: 25 }}>
                  {price}
                </p>
              </div>
            )}
          </div>
          <div className="careerApp__main__btn">
            <Button width="250px" onClick={handleSubmit}>
              {loading ? <Loading button={true} /> : "Enroll"}
            </Button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="careerApp__main">
          <h3 style={{ textAlign: "center", fontSize: 20 }} className="suc">
            Application submitted. Thank you for enrolling. We will reach out to
            you soon.
          </h3>
        </div>
      )}
    </div>
  );
};

export default TrainingApp;
